<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <BreadCrumbs :params="{navigation, color: 'rgba(0, 0, 0, .5)'}"/>
      </Section>
      <Section limiter>
        <Row justify="between">
          <AccountMenu v-if="$laptop"
                       class="grid-md-4 grid-lg-4"/>
          <AccountContent class="grid-sm-full grid-md-8 grid-lg-8"
                          :params="{transparent: true}">
            <Row class="m-b-40">
              <h5 class="account-content__favorites-title">Хочу посетить</h5>
            </Row>
            <Row class="account-content__favorites-tabs">
                <span v-for="tab in tabs"
                      :key="tab.id"
                      class="account-content__favorites-tab"
                      v-on:click="check(tab.signature)"
                      :class="currentFilter === tab.signature ?
                      'account-content__favorites-tab-active' : ''">{{tab.title}}</span>
            </Row>
            <Row v-if="$favorites.excursions.length > 0 ||
                       $favorites.places.length > 0 ||
                       $favorites.events.length > 0">
                <span v-if="$favorites.excursions.length > 0"
                      class="account-content__favorites-count">{{$favorites.excursions.length}}
                  {{plural($favorites.excursions.length,
                  ['маршрут', 'маршрута', 'маршрутов'])}}</span>

              <span v-if="$favorites.places.length > 0"
                    class="account-content__favorites-count">{{$favorites.places.length}}
                  {{plural($favorites.places.length, ['объект', 'объекта', 'объектов'])}}</span>

              <span v-if="$favorites.events.length > 0"
                    class="account-content__favorites-count">{{$favorites.events.length}}
                  {{plural($favorites.events.length, ['событие', 'события', 'событий'])}}</span>

            </Row>
            <Row v-else>
              <p class="account-dashboard__empty-favorites">
                Здесь будут отображаться объекты, маршруты и события, добавленные в закладки</p>
            </Row>
            <Row class="-m-15"
                 wrap>
              <component :is="componentType(item)"
                         :key="item.id"
                         :params="item"
                         class="grid-md-4 grid-lg-4"
                         v-for="item in arrays"/>
            </Row>
          </AccountContent>
        </Row>
      </Section>
<ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountFavoritesView',
  data() {
    return {
      navigation: [{
        href: '/',
        title: 'Главная',
      }, {
        href: '/account',
        title: 'Личный кабинет',
      }],
      tabs: [{
        title: 'Все',
        signature: 'all',
      }, {
        title: 'Объекты',
        signature: 'places',
      }, {
        title: 'Маршруты',
        signature: 'excursions',
      }, {
        title: 'События',
        signature: 'events',
      }],
      currentFilter: 'all',
    };
  },
  created() {
    this.$store.dispatch('GET_FAVORITES_EVENTS_FROM_SERVER');
    this.$store.dispatch('GET_FAVORITES_TRIPS_FROM_SERVER');
    this.$store.dispatch('GET_FAVORITES_PLACES_FROM_SERVER');
  },
  computed: {
    arrays() {
      let array = [];
      if (this.currentFilter === 'all') {
        array = [...this.$favorites.excursions,
          ...this.$favorites.places,
          ...this.$favorites.events];
      } else {
        array = [...this.$favorites[this.currentFilter]];
      }
      return array;
    },
  },
  methods: {
    componentType(item) {
      if (typeof item.type === 'string' && item.type === 'route') return 'TripCard';
      if (typeof item.type === 'string' && item.type === 'excursion') return 'ExcursionCard';
      if (typeof item.type === 'object') return 'PlaceCard';
      return '';
    },
    plural(n, titles) {
      // eslint-disable-next-line no-nested-ternary,max-len
      return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    },
    check(signature) {
      this.currentFilter = signature;
    },
  },
  watch: {
    $user() {
      this.$redirectIfGuest();
    },
  },
};
</script>
